@import 'utilities.css';
:root{
--primary: rgb(29, 221, 189);
--bgDark: rgb(12, 12, 12);
--white: rgb(250, 250, 250);
--secondary: rgb(0, 59, 50);
--bgLight: rgb(190, 181, 181);
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-color: var(--bgDark);
}
header{
    background-color: var(--bgDark);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 73% 94%, 0 100%);
    position: sticky;
    top: 0;
}
header nav .left a{
    color: var(--white);
    text-decoration: none;
    margin-right: 2rem;
    text-transform: uppercase;
    transition: all .2s ease;
}
header nav .left a:hover{
    color: var(--primary);
}
header nav {
    padding: 2rem 0;
}
header nav .logo{
    margin-right: 3rem;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%
}
  
/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

body{
    font-family: 'Poppins', sans-serif;
    }
.container{
    max-width: 1152px;
    padding: 0 15px;
    margin: 0 auto;
}
.hero{
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.hero .center-title{
    padding-bottom: 2em;
}

.hero .left img{
    width: 400px;
    
    align-items: center;
}
.hero .right {
    color: var(--white);
    margin-top: -7rem;
}
.hero .right h6{
    font-size: 1.6rem;
    color: var(--primary);
    margin-bottom: 0.5rem;
}
.hero .right h1{
    font-size: 4rem;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 2rem;
}
.hero .right h1 span{
    color: var(--primary);
}
.hero .right p{
    line-height: 1.9;
    margin-bottom: 2rem;
}

.hero .center a{
    margin: auto;
    width: 413px;
    display: block;
}

.hero .center img{
    display: block;
}

.hero .center h1{
    font-size: 4rem;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 2rem;
    text-align: center;
}
.hero .center h1 span{
    color: var(--primary);
}


.hero .separate hr{
    background-color: var(--primary);
    height: 5px;
}

.container {
    overflow: auto;
  }

/* BEGIN: Footer */
footer{
    background-color: var(--bgDark);
    color: var(--white);
    padding: 3rem 0;
    text-align: center;
}


/* BEGIN: Centered Form */
.form-container {
    display: block;
    justify-content: center;
    align-items: center;
    height: 25%;
    width: 400px;
    margin-bottom: 3rem;
    border: 2px solid var(--primary);
    border-radius: 5px;
    max-width: 20%;
    padding: 15px 15px;
    margin: 0 auto;
}

.form-label {
    color: var(--white);
    font-weight: bold;
}

.form-error-label {
    color: red;
    font-weight: bold;
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border: 1px solid var(--primary);
    border-radius: 3px;
    background-color: white;
}

.form-button {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--primary);
    color: var(--white);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 2rem;
}

/* END: Centered Form */
